$white: #ffffff;
$pattens_blue: #E4EAEC;
$dodgerblue: #2196F3;
$dodgerBlueHover: #2188DA;
$black: #000000;
$charcoal: #424242;
$nobel: #999999;
$nobel_first: #959595;
$snow: #FCFCFC;
$gainsboro: #DADADA;
$silver: #C4C4C4;
$powder: #3F3D56;
$hoki: #607D8B;

$bg-fields: #FCFCFC;
$fields-border-color: #E4EAEC;

$breakpoint-lg: 1439px;
$breakpoint-md: 1279px;
$breakpoint-sm: 1023px;
$breakpoint-xs: 767px;
